export default Object.freeze({
  'bagatelle-los-cabos': {
    name: 'Bagatelle',
    code: 'bagatelle',
    caption: 'The French Joie De Vivre',
    logo:
      'https://imgix.cosmicjs.com/9d1236e0-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos__logo.jpg',
    smallLogo:
      'https://imgix.cosmicjs.com/9d1236e0-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos__logo.jpg',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fe0304,FA8072&duotone-alpha=100&px=10&auto=format`,
    secondaryColor: '#817d7d',
    color: '#4a1f1f',
    video: {
      title: 'BAGATELLE CABOS',
      youtube: 'true',
      content:
        'An exquisite way to celebrate',
      id: 'AOSP3qmFUaU',
      videoUrl: 'https://www.youtube.com/embed/AOSP3qmFUaU',
    },
  },
  'crania-los-cabos': {
    name: 'Crania',
    caption: 'Cocktail Bar & Air Disco',
    code: 'crania',
    logo:
      'https://imgix.cosmicjs.com/7125ac70-f79b-11ee-b555-0d0678c27dd7-crania-los-cabos-logo.png',
    smallLogo:
      'https://imgix.cosmicjs.com/9d1236e0-f62a-11ee-b555-0d0678c27dd7-BAGATELLE_LOS_CABOS___bagatelleloscabos__logo.jpg',
    color: 'rgb(164 88 87)',
    secondaryColor: '#313a37',
    video: {
      title: 'EINMUSIK @ Crania Los Cabos',
      youtube: 'true',
      content:
        'A crane made paradise',
      id: 't_lltJgcAIs',
      videoUrl: 'https://www.youtube.com/embed/AOSP3qmFUaU',
    },
  },
  "rosa-negra-los-cabos": {
    name: 'Rosa Negra Los Cabos',
    code: 'rosa-negra',
    caption: 'Latin America Cuisine & Live Show',
    logo:
      'https://imgix.cosmicjs.com/465750c0-0263-11ef-bec5-7f9403a32f29-rosa-negra-los-cabos-logo.png',
    smallLogo:
      'https://imgix.cosmicjs.com/465750c0-0263-11ef-bec5-7f9403a32f29-rosa-negra-los-cabos-logo.png',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fe0304,FA8072&duotone-alpha=100&px=10&auto=format`,
    secondaryColor: '#1f2222',
    color: '#959595',
    video: {
      title: 'Rosa Negra Cabos',
      youtube: 'true',
      content:
        '<p>💥 Food, Show & Sparkles<br><strong>A delicious & fun </strong> experience</p>',
      id: 'KKWMkgqrlIc',
      videoUrl: 'https://www.youtube.com/embed/KKWMkgqrlIc',
    },
  },
  'taboo-los-cabos': {
    name: 'Taboo Los Cabos',
    code: 'taboo',
    caption: '',
    logo:
      'https://imgix.cosmicjs.com/9db3edc0-f62d-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos-logo.jpg',
    smallLogo:
      'https://imgix.cosmicjs.com/9db3edc0-f62d-11ee-b555-0d0678c27dd7-Taboo_Los_Cabos___tabooloscabos-logo.jpg',
    bgPalm: `https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=fe0304,FA8072&duotone-alpha=100&px=10&auto=format`,
    secondaryColor: '#46b9e0',
    color: '#67a59a',
    video: {
      title: 'Taboo Los Cabos',
      youtube: 'true',
      content:
        '<p>💥 An explosive club experience.<br><strong>A hidden world</strong>, a whispered name</p>',
      id: 'gjK5-m_msro',
      videoUrl: 'https://www.youtube.com/embed/gjK5-m_msro',
    },
  },
  "funky-geisha-los-cabos": {
    name: 'Funky Geisha',
    code: 'funky-geisha',
    caption: 'Asian Cusine & Taiko Drum Show',
    secondaryColor: '#a06f3b',
    color: '#b88b68',
    logo:
      'https://imgix.cosmicjs.com/193ed1a0-6c9c-11ef-b5ae-a594bb4a8e67-Logo-Funky-Geisha.jpg',
  },
  "craft-los-cabos": {
    name: 'Chambao Los Cabos',
    code: 'craft',
    caption: 'Open Fire Kitchen',
    secondaryColor: '#565d75',
    color: '#514437',
    logo:
      'https://imgix.cosmicjs.com/56658940-6c90-11ef-b5ae-a594bb4a8e67-Logo-Craft-Los-Cabos.png',
  },
  "chambao-los-cabos": {
    name: 'Chambao Los Cabos',
    code: 'chambao',
    caption: 'Fashion Grillhouse & Live Performances',
    logo:
      'https://imgix.cosmicjs.com/65adb750-1228-11ef-9eca-7d347081a9fb-chambao-los-cabos-logo.jpg',
    smallLogo:
      'https://imgix.cosmicjs.com/65adb750-1228-11ef-9eca-7d347081a9fb-chambao-los-cabos-logo.jpg',
    secondaryColor: '#db9570',
    color: '#876247',
    video: {
      title: 'Chambao Los Cabos',
      youtube: 'true',
      content:
        '<h5>Bad Decisions, <strong>Make Better Stories</strong></h5>',
      id: 'Bl2IdvbD6aA',
      videoUrl: 'https://www.youtube.com/embed/Bl2IdvbD6aA',
    },
  }
});
